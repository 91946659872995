<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Mash tank details
            <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
          </h5>

          <hr />
          <b-row>
            <b-col lg="6" md="6" sm="6">
              <inline-input
                :id="controls.tank_number.id"
                :value="data.tank_number"
                :label="controls.tank_number.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6" sm="6">
              <inline-input
                :id="controls.capacity.id"
                :value="data.capacity"
                :label="controls.capacity.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6" sm="6">
              <inline-input
                :id="controls.created.id"
                :value="data.created"
                :label="controls.created.label"
                :readonly="true"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <hr />
        </form>

        <hr />
        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey(dataset, id),
            next: $customTable.getNextKey(dataset, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey(dataset, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey(dataset, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Mash tanks' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Mash tank submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Mash tank submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import FormSubmissionActions from "@/components/FormSubmissionActions";

import InlineInput from "@/components/InlineInput";

import { mapState, mapActions } from "vuex";

export default {
  name: "MashTankSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,

    FormSubmissionActions
  },
  data: function() {
    return {
      dataset: {
        name: "mash-tanks"
      },
      baseUrl: "mash-tank",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        tank_number: {
          id: "input:tank_number",
          label: "Tank Number",
          changed: false,
          readonly: false
        },
        capacity: {
          id: "input:capacity",
          label: "Capacity",
          changed: false,
          readonly: false
        },

        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: false
        }
      },
      data: {
        id: "",
        tank_number: "",
        capacity: "",
        created: ""
      },
      datasetKeys: []
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    initialize: async function() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      this.fetchDropdowns();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchData(this.id);
      }
    },

    fetchDropdowns() {},
    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let url = this.baseUrl,
        method = "put";

      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        url = `${this.baseUrl}/${this.data.id}`;
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value, mode) {
      this.$form.updateField(this.baseUrl, this, field, value, mode);
    },
    updateSelect(id, value) {
      this.$form.updateField(this.baseUrl, this, id, value, this.mode);
    },

    async fetchData(id) {
      let self = this;

      this.isLoading = true;
      return this.$api
        .get(`${this.baseUrl}/${id}`)
        .then(response => {
          this.isLoading = false;

          if (self.$_.isEmpty(response)) return;

          self.data.id = self.id;

          self.data.tank_number = response["Tank Number"];
          self.data.capacity = response["Capacity"];
          self.data.created = response["Created"];
        })
        .catch(error => {
          this.isLoading = false;

          console.log(error);
        });
    }
  },
  watch: {}
};
</script>

<style></style>
